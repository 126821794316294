<template>
	<div class="page-cases">
		<div class="detail">
			<div class="custom-headline no-line">高出平均销售额220%+，揭秘快充时刻如何成为“食堂爆款”！</div>
			<div class="tag">国美家营销</div>
			<div class="img-box">
				<el-image :src="imgList[0]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[1]" lazy></el-image>
			</div>
			<div class="text">2021年9月，快充时刻作为一家刚刚创立几个月的快餐品牌，在入驻国美家美食美客商务食堂后，迅速成为周边消费者⻘睐的爆款品牌之一。2021年10月，快充时刻在美食美客食堂营业仅30天，就以销售额高于平均水平220%的优势展现出了惊人的爆发力，成为美食美客最受欢迎的快餐品牌之一。 截截止目前，快充时刻已经连续实现整体盈利，营收业绩持续向好。那么这家快餐品牌具体是如何做的？今天我们一起来细细拆解，快充时刻销售业绩遥遥领先的秘诀。</div>
			<div class="img-box">
				<el-image :src="imgList[2]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[3]" lazy></el-image>
			</div>
			<div class="img-box">
				<el-image :src="imgList[4]" lazy></el-image>
			</div>
			<div class="text">快充时刻虽为新生品牌，但创始人已有20余年餐饮行业经营经验。作为美食美客商务食堂第一批入驻商户，快充时刻深谙时效性的重要价值。商务人群中午休息时间有限，常因会议等原因未能准时下楼用餐。因此，为顾客提供及时、高效的服务是首先要解决的问题。如何建立起更加高效的出餐模式，提高出餐效率？如何在同一时间段内完成更多单量的售卖，降低客户的等待时间？快充时刻的老板一直在思考。基于多年的餐饮经验，快充时刻意识到： 规范出餐流程、明确各节点分工是提高效率的关键所在。从整体上来说，出餐流程总计分为四个关键环节，分别是：招揽顾客、打菜、打包、结账。每个节点安排1人完成，专人专岗，各司其职，形成完整快速的操作闭环，并且每个人的核心动作只有一个，可快速重复，大大提高整个出餐流程的效率，单量自然也随之增⻓起来了。同时，快充时刻采用全职+兼职的雇佣模式，在高峰时段增加兼职人员的数量，这样既提高了效率，口降低了总体用人成本。正是基于这一链条关系的基础奠定，快充时刻通过优质的菜品、丰富的种类、亲民的价格以及快速的出餐速度等优势在消费群体中迅速站稳脚跟。销售数据显示，快充时刻在同一时段的出餐量上，最高可达到日平均订单量的167%。创造了美食美客商务食堂订单量的峰值。</div>
			<div class="img-box">
				<el-image :src="imgList[5]" lazy></el-image>
			</div>
			<div class="text">开业以来，快充时刻始终保持着对于顾客的消费体验的高度重视。 为确保每天的菜品口味，每天菜品出炉后，厨师和负责人都会盛出小部分菜品进行试菜，如果发现菜品口味过重或其他状况，则反复调整优化口味，同时减少菜品的售卖量。就餐高峰期，当熙熙攘攘的人群在食堂不同的商铺前穿流而过，快充时刻的工作人员看到客户后会第一时间主动热情的招揽客户，简单介绍菜品，吸引大家的注意力。在菜品的搭配上，也考虑得十分周全。 如某个菜的品偏辣，在组合搭配时，会选择带些汤汁的菜品进行中合。如某个菜的肉量较多，则会选择木耳、⻘笋等解腻的⻘菜进行搭配。在客户选择堂食时，快充时刻的服务人员将餐盘递给客户时，总会提醒客户：“如果米饭不够，可以再来加。”这样既能让顾客吃饱，也能避免不必要的浪费。 对于一些反复回购的老客户，快充时刻服务人员总会记住客户的口味和喜好。比如有的客户喜欢吃锅包肉，如果当天有做相应的菜品，就会给客户发微信提醒。好的服务往往体现于细节之处，这无疑能带来与消费群体强大的情感链接，一定程度上为顾客的⻓期复购带来相当重要的价值。</div>
			<div class="img-box">
				<el-image :src="imgList[6]" lazy></el-image>
			</div>
			<div class="text">无论是上文提到的多快好省的菜品策略、还是更优的服务细节的关注，背后离不开⼈所发挥的作用。而快充时刻通过对于员工优势条件的充分运用，根据不同人的性格，进行不同的人员分工。如性格外向开朗的，负责招揽客户，手脚麻利的，负责打菜拼菜，熟悉系统的，负责收款结账等。既能提高效率，也能让员工做自己最擅长的事情。从快充时刻的案例中，我们看到了提高运营效率及优化服务体验所带来的价值，值得更多的商户伙伴借鉴和思考，从而带来更上一层楼的经营效果及营收。当然，一家店铺的运营并不是一蹴而就的，而是一个厚积薄发的过程。期待加入美食美客商务食堂的所有的商户伙伴，稳扎稳打，做的更好！</div>
		</div>
		<!-- 放在外层与浏览器同宽 -->
		<div class="more-cases">
			<div class="title">更多精彩案例</div>
			<div class="cases-item">
				<div class="case-content-con" v-for="item in caseList" :key="item.id"
					@click="jump2casesDetail(item.id)">
					<el-image class="case-img" :src="item.image" fit="fill">
					</el-image>
					<div class="case-content-box">
						<div class="case-content-title">
							{{ item.title }}
						</div>
						<div class="case-content-des">{{ item.des }}</div>
						<div class="case-content-time">{{ item.date }}</div>
						<div class="case-content-sign">阅读全部 ></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imgList: [
				require("../assets/img/cases/demo2/1.png"),
				require("../assets/img/cases/demo2/2.png"),
				require("../assets/img/cases/demo2/3.png"),
				require("../assets/img/cases/demo2/4.png"),
				require("../assets/img/cases/demo2/5.png"),
				require("../assets/img/cases/demo2/6.png"),
				require("../assets/img/cases/demo2/7.png"),
			],
			caseList: [
				{
					id: 1,
					image: require("../assets/img/cases/demo1/2.png"),
					title: "开业45天，日销连续稳居前三，高于平均销售额120%的商户是如何经营的？",
					des: "自国美家美食美客商务食堂出现在大众视野。“香鼎坊”作为入驻品牌之一，在过去45天的经营过程中业绩 遥遥领先，在销售额和订单量上⻓期居于前列，交出了亮眼的答卷。",
					date: "2021.09.07",
				},
				{
					id: 3,
					image: require("../assets/img/cases/demo3/1.jpg"),
					title: "商户版-国美家商户扶持活动-美丽雅",
					des: "美丽雅女王节活动”是国美家与成都家居清洁用品龙头企业美丽雅，基于3月8日妇女节打造的“女王节”推广活动。本次活动主要通过纯线上的社群运营在全国范围内推广，是美丽雅＆国美家首次合作，也是国美家商户扶持政策的重要组成部分。",
					date: "2022.03.08",
				},
			],
		};
	},
	methods: {
		jump2casesDetail(id) {
      this.$router.push({
        name: `CasesDetail${id}`,
      })
    },
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
img {
	width: 100%;
	margin: 0 auto;
}
.page-cases {
	position: relative;
	width: 1200px;
	margin: auto;
	text-align: left;
	margin-top: 20px;
	.detail {
		.no-line {
			font-size: 26px;
			color: #1f2329;
			margin-bottom: 17px;
			&::after {
				background: none;
			}
		}
		.tag {
			font-size: 12px;
			color: #f08107;
			width: 90px;
			height: 32px;
			line-height: 32px;
			background-color: #feead2;
			text-align: center;
			margin-bottom: 17px;
		}
		.img-box {
			margin: 26px 0;
		}
		.text {
			text-indent: 2em;
		}
	}
}
.more-cases {
	.title {
		position: relative;
		font-size: 22px;
		font-weight: 500;
		color: #1f2329;
		text-align: center;
		margin-top: 90px;
		margin-bottom: 50px;
		&::after {
			content: "";
			position: absolute;
			bottom: -5px;
			left: 0;
			right: 0;
			width: 50px;
			height: 5px;
			margin: 0 auto;
			border-radius: 5px;
			background: linear-gradient(to right, #f6ba4f, #ea8427);
		}
	}
}
.cases-item {
	display: flex;
	.case-content-con {
		width: 386px;
		height: 523px;
		background: #fafafa;
		border-radius: 10px;
		margin-right: 60px;
		background-color: #fff;
		cursor: pointer;
		transition: box-shadow 0.3s linear;
		&:hover {
			cursor: pointer;
			box-shadow: 0px 6px 6px 0px #e9edf1;
		}
		.case-img {
			width: 385px;
			height: 201px;
			border-radius: 10px;
		}
		.case-content-box {
			width: 330px;
			padding: 40px 28px 26px 28px;
		}
		.case-content-title {
			width: 100%;
			height: 30px;
			font-size: 20px;
			font-family: HiraginoSansGB-W6, HiraginoSansGB;
			font-weight: normal;
			color: #1f2329;
			line-height: 30px;
			overflow: hidden;
			white-space: nowrap; /*把文本强制显示在一行*/
			text-overflow: ellipsis; /*超出部分显示...*/
		}
		.case-content-des {
			margin-top: 16px;
			width: 100%;
			// height: 74px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 24px;
			overflow: hidden;
			text-overflow: ellipsis; /*超出部分显示...*/
			display: -webkit-box;
			line-clamp: 3;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		.case-content-time {
			margin-top: 16px;
			height: 19px;
			font-size: 14px;
			// font-family: MicrosoftYaHei;
			color: #666666;
			line-height: 19px;
		}
		.case-content-sign {
			width: 91px;
			height: 24px;
			font-size: 18px;
			// font-family: MicrosoftYaHei;
			color: #eb8729;
			line-height: 24px;
			margin-top: 66px;
			cursor: pointer;
		}
	}
}
</style>